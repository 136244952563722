
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import listConnectAvailable from "../../../store/connect-available";

interface Result {
  data: any;
  message: any;
  name: string;
  type: string;
  status: boolean;
}

@Options({
  props: {
    fromType: "",
    applicationIds: {},
    callbacks: {},
    connectData: {},
  },
})
export default class SettingPage extends Vue {
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
     account: localStorage.getItem("account"),
  };
  url = `${process.env.VUE_APP_BASE_URL}/`;
  listData: any = [];
  connectAvailable: any = [];
  fromType = "";
  //
  dataTo: any = {};
  //
  options: any = [];
  listPrice: any = [];
  //
  titleToPlatForm = "";
  //
  applicationIds: any = {};
  callbacks: any = {};
  connectData: any = {};
  //
  dialogToPlatform = false;
  visible = false;
  //
  newConnectId: any = "";
  accountSelected: any;
  connectExist: any = [];

  async created() {
    this.accountSelected = await this.$store.state.auth.accountSelected;
    for (let connect of this.connectData) {
        this.connectExist.push(connect.type);
    }
    await this.getListPlatform();
    if (this.$route.query.isError && this.$route.query.isError !== "false") {
      await this.$message.warning(
        "Update token fail, Please contact us for instructions!!!"
      );
      this.handleDeleteConnect(this.$route.query.connectionId);
      this.$router.replace({ query: {} });
    }
    if (
      this.$route.query.isError &&
      this.$route.query.isError === "false" &&
      this.$route.query.message
    ) {
      await this.$message.success(this.$route.query.message);
      this.$router.replace({ query: {} });
      this.$emit("finishHandle", this.$route.query.type);
    }
  }
  async getListPlatform() {
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: any = await axios.get("admin/platform", {
      headers: this.headers,
    });
    if (res) {
      this.listData = res.data;
      // console.log(this.listData);

      listConnectAvailable.forEach((connect, i) => {
        if (connect.hub == this.fromType) {
          this.connectAvailable.push(connect.target);
        }
      });
      this.listData.forEach((platform: any = {}, i: number) => {
        if (platform.type == this.fromType) {
          this.listPrice = platform.listPrice;
        //   this.listData.splice(i, 1)
          // console.log(this.listPrice);
        }        
        
        if (this.connectAvailable.includes(platform.type)) {
          platform.available = true;          
        } else platform.available = false;
      });
      if (this.listPrice.length > 0) {
          
        this.listData.forEach((platform: any = {}, i: number) => {
          this.listPrice.forEach((itemPrice: any = {}) => {
            if (
              itemPrice.target.toLowerCase() === platform.type.toLowerCase()
            ) {
              if (this.connectExist.includes(platform.type)) {
                 if (
                  this.connectExist.filter((x: any) => x === platform.type)
                    .length <= 2
                ) {
                  platform.price = (
                    (itemPrice.price * (100 - platform.discountlv1)) /
                    100
                  ).toFixed(2);
                  platform.discount = platform.discountlv1;
                }
                if (
                  this.connectExist.filter((x: any) => x === platform.type)
                    .length >= 3
                ) {
                  platform.price = (
                    (itemPrice.price * (100 - platform.discountlv2)) /
                    100
                  ).toFixed(2);
                  platform.discount = platform.discountlv2;
                }
              } else platform.price = itemPrice.price;
              if (this.accountSelected.isFreeAccount) {
                platform.price = 0;
              }
            }
          });
        });
      }
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  selectToPlatform(platform: any) {
    this.titleToPlatForm = `${platform.name} connection.`;
    this.options = platform.options;
    for (let item of this.options) {
      this.dataTo[item.key] = "";
    }
    this.dataTo.name = "";
    this.dataTo.type = platform.type;
    this.dataTo.platform = platform._id;
    this.dataTo.price = platform.price;
    this.dataTo.discount = platform.discount;
    this.dialogToPlatform = true;
  }
  async saveToConnect() {
    await this.$store.dispatch("setLoading", true, { root: true });
    const refFrorm = this?.$refs["refForm"] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;
    this.dataTo.connectFrom = false;
    this.dataTo.connectTo = true;    
    let res: Result;
    console.log(this.headers);
    
    res = await axios.post("user/connect", this.dataTo, {
      headers: this.headers,
    });
    if (res.data) {
      this.dialogToPlatform = false;
      this.newConnectId = res.data._id;
      if (res.data.type == "shopify" || res.data.type == "channelAdvisor" || res.data.type == "quickbook") {
        this.handleUpdateToken(res.data);
      } else {
        this.$emit("finishHandle", res.data);
                this.connectExist.push(this.dataTo.type);
        this.$message.success(res.message);
        await this.getListPlatform();
      }
    } else {
      this.$message.warning(res.message);
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  nextStep() {
    this.$emit("nextStep");
  }
  reSelect() {
    this.$emit("reSelect");
  }
  handleViewSelect() {
    this.$emit("viewSelect");
  }

  async handleDeleteConnect(id: any) {
    await axios
      .delete("user/connect/delete-fail-add-token", {
        params: { id: id },
        headers: this.headers,
      })
      .then(
        function (response: any) {
          console.log(response.message);
        },
        function (response: any) {
          console.log(response.message);
        }
      );
  }
  async handleUpdateToken(connection: any) {
    await this.$store.dispatch("setLoading", true, { root: true });
    let query: any;
    let urlPath: any = null;
    if (connection.type == "shopify") {
      query = this.encodeQueryData({
        client_id: this.applicationIds.shopify,
        scope:
          "write_payment_terms,read_publications,read_products,write_products,read_orders,write_orders,read_all_orders,read_inventory,write_inventory,read_draft_orders,write_draft_orders,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,read_shipping,write_shipping,read_fulfillments,write_fulfillments",
        redirect_uri: this.callbacks.shopify,
        state: JSON.stringify({
          tokenUser: localStorage.getItem("tokenUser"),
          accountId: localStorage.getItem("account"),
          connectionId: connection._id,
          currentPage: window.location.href,
        }),
      });
      urlPath =
        "https://" +
        connection.shopify_shopName +
        ".myshopify.com/admin/oauth/authorize?" +
        query;
    }
    if (connection.type == "channelAdvisor") {
      query = this.encodeQueryData({
        client_id: this.applicationIds.channelAdvisor,
        response_type: "code",
        access_type: "offline",
        scope: "orders inventory",
        approval_prompt: connection?.tokenObject?.hasRefreshToken
          ? "auto"
          : "force",
        redirect_uri: this.callbacks.channelAdvisor,
        state: JSON.stringify({
          tokenUser: localStorage.getItem("tokenUser"),
          accountId: localStorage.getItem("account"),
          connectionId: connection._id,
          currentPage: window.location.href,
        }),
      });
      urlPath = "https://api.channeladvisor.com/oauth2/authorize?" + query;
    }
    if (connection.type === "esty") {
      query = this.encodeQueryData({
        client_id: this.applicationIds.esty.user,
        response_type: "code",
        code_challenge: this.applicationIds.esty.codeChallenge,
        code_challenge_method: this.applicationIds.esty.codeChallengeMethod,
        scope: this.applicationIds.esty.scopes.join(" "),

        redirect_uri: this.callbacks.esty,
        state: JSON.stringify({
          tokenUser: localStorage.getItem("tokenUser"),
          accountId: localStorage.getItem("account"),
          connectionId: connection._id,
          currentPage: window.location.href,
          codeVerifier: this.applicationIds.esty.codeVerifier,
        }),
      });
      urlPath = "https://www.etsy.com/oauth/connect?" + query;
    }
    if (connection.type === "quickbook") {
      urlPath = this.applicationIds.quickbook.urlAuth;
      if (urlPath) {
        urlPath = urlPath
          .replace('tokenUser_iomnic', encodeURIComponent(localStorage.getItem("tokenUser") || ''))
          .replace('accountId_iomnic', localStorage.getItem("account"))
          .replace('connectionId_iomnic', connection._id)
          .replace('currentPage_iomnic', encodeURIComponent(window.location.href))
      }
    }
    if (urlPath != null) {
      window.open(urlPath, "_self", "");
      window.close();
      // this.$nextTick( async () => {
      //     let oauthState: any = await this.showAuthWindow({
      //         path: urlPath
      //     })
      //     if (Object.keys(oauthState).length && oauthState.isError === false) {
      //         this.$message.success(oauthState.message);
      //         this.$emit("finishHandle", connection.type, connection)
      //         await this.$store.dispatch("setLoading", false, { root: true });
      //     } else {
      //         this.$message.warning("Update token fail, Please contact us for instructions!!!");
      //         await this.handleDeleteConnect(connection._id)
      //         await this.$store.dispatch("setLoading", false, { root: true });
      //     }
      // })
    }
  }

  showAuthWindow(options: {
    path: any;
    windowName?: any;
    windowOptions?: any;
  }) {
    return new Promise((resolve, reject) => {
      options.windowName = options.windowName || "ConnectWithOAuth";
      options.windowOptions =
        options.windowOptions || "location=0,status=0,width=800,height=600";
      var _oauthWindow: any = open(
        options.path,
        options.windowName,
        options.windowOptions
      );
      _oauthWindow.focus();
      var oauthState = {};

      function onPopupSendMessage(event: any) {
        if (event.data.event === "oauthState") {
          oauthState = event.data;
        }
      }

      window.addEventListener("message", onPopupSendMessage);

      var _oauthInterval = setInterval(() => {
        if (!_oauthWindow.closed) return;
        clearInterval(_oauthInterval);
        resolve(oauthState);
        window.removeEventListener("message", onPopupSendMessage);
      }, 1000);
    });
  }
  encodeQueryData(data: any) {
    let ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
  }
  handleOpenEmail() {
    window.location.href = 'mailto:support@wiserobot.com';
  }
}
